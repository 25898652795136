import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.to-string.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1639e6aa"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dropdown"
};
var _hoisted_2 = {
  class: "active"
};
var _hoisted_3 = ["onClick"];
import { reactive } from 'vue';
export default {
  props: {
    "modelValue": {
      type: Object,
      required: true
    },
    "entries": {
      type: Object,
      required: true
    },
    "labelFn": {
      type: Function,
      default: function _default(entry) {
        return entry;
      }
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var state = reactive({
      visible: false
    });

    var handleMouseEnter = function handleMouseEnter() {
      state.visible = true;
    };

    var handleMouseLeave = function handleMouseLeave() {
      state.visible = false;
    };

    var entryClicked = function entryClicked(type) {
      emits('update:modelValue', type);
      handleMouseLeave();
    };

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "dropdown-container",
        onMouseleave: handleMouseLeave
      }, [_createElementVNode("div", {
        class: "selection active",
        onMouseenter: handleMouseEnter
      }, _toDisplayString(__props.labelFn(__props.modelValue)), 33), _createVNode(_Transition, {
        name: "fast-fade"
      }, {
        default: _withCtx(function () {
          return [_withDirectives(_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.labelFn(__props.modelValue)), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.entries, function (entry) {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: entry,
              onClick: function onClick() {
                return entryClicked(entry);
              }
            }, _toDisplayString(__props.labelFn(entry)), 9, _hoisted_3)), [[_vShow, __props.modelValue.toString() !== entry.toString()]]);
          }), 128))], 512), [[_vShow, _unref(state).visible]])];
        }),
        _: 1
      })], 32);
    };
  }
};